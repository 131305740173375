

















































































































































































































































































































































































import { Component, Vue } from 'vue-property-decorator';
import MaterialCard from '@/components/MaterialCard.vue';
import ConfirmActionDialog from '@/components/ConfirmActionDialog.vue';

// Models
import { Estate } from '@/models/Estate.model';

// --- Services ---
import EstateService from '@/services/estate';
import AppService from '@/services/app';
import AuthService from '@/services/auth';

// --- Third Party imports ---
import Hashids from 'hashids';
import scanHardware from '@/services/scanHardware';

@Component({
  components: {
    MaterialCard,
    ConfirmActionDialog,    
  },
})

export default class Estates extends Vue {
  hashids = new Hashids(process.env.VUE_APP_HASHIDS_SALT);

  // used by Filter Area
  search: string = '';

  // used by No Data Slot
  isLoading: boolean = true;
  confirmActionDelay: boolean = false;

  // used by Item Dialog for Create/Update
  showItemDialog: boolean = false;
  isView: boolean = false;

  clickedItem: Estate = {
    id: 0,
    vendorId: 0,
    shortCode: '',
    name: '',
    address: '',
    contactNumber: '',
    defaultCodeLimit: 0,
    suspendedBy: 0,
    suspended: false,
    createdBy: 0,
    createdAt: '',
    updatedAt: '',
    guardCount: 0,
  };

  valid: boolean = false;

  // used by Confirm Action Dialog for Delete Item
  action: string = '';
  dialogContent: string = '';
  showConfirmActionDialog: boolean = false;

  title: string = '';
  isUpdate: boolean = false;

  rules = {
    required: (value: string) => !!value || '',
    email: (value: string) => /(?:[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/.test(value) || 'E-mail must be valid',
    cellNumber: (value: string) => /^[0-9]{10}$/.test(value) || 'Please enter a valid Phone Number',
    numbersOnly: (value: string) => (/^\d+$/.test(value)) || 'Invalid character',
  };

  clicked: boolean = false;

  // region Functions to display list data on page load
    headers() {
      return [
        {
          text: 'Name',
          value: 'name',
        },
        {
          text: 'Contact Number',
          value: 'contactNumber',
        },
        {
          text: 'Address',
          value: 'address',
        },
        {
          text: 'Guard Count',
          value: 'guardCount',
        },
        {
          text: 'Default Code Limit',
          value: 'defaultCodeLimit',
        },
        {
          text: 'Short Code',
          value: 'shortCode',
        },
        {
          text: 'Created At',
          value: 'createdAt',
        },
        {
          sortable: false,
          text: '',
          value: 'action',
          align: 'end',
        },
      ];
    }

    async beforeMount() {
      await this.listAll();
    }

    async listAll() {
      try {
        this.isLoading = true;
        const response: string = await EstateService.listAll(true);

        if (response.length > 0 && response !== 'No Data Found') {
          AppService.errorHandler(response);
        }

      } finally {
        this.isLoading = false;
      }
    }

    // #region Functions required for the Item Dialog for Create/Update
      async launchNewItemDialog() {
        this.clickedItem = {
          id: 0,
          vendorId: 0,
          shortCode: '',
          name: '',
          address: '',
          contactNumber: '',
          defaultCodeLimit: 0,
          suspendedBy: 0,
          suspended: false,
          createdBy: 0,
          createdAt: '',
          updatedAt: '',
          guardCount: 0,
        };

        this.title = 'Create New Estate';

        this.isUpdate = false;
        this.showItemDialog = true;
      }

      async launchViewItemDialog(item: Estate) {
      this.clickedItem = Object.assign({}, item);

      this.isView = true;
      this.valid = false;
      this.title = this.clickedItem.name;
      this.showItemDialog = true;
    }

      launchUpdateItemDialog(item: Estate) {
      this.clickedItem = Object.assign({}, item);

      this.title = 'Update ' + (this.clickedItem.name);

      if (this.$refs.itemForm) {
        (this.$refs.itemForm as any).resetValidation();
      }

      this.showItemDialog = true;
    }

      doCreateUpdate() {
      if (this.clickedItem.id <= 0) {
        this.doCreate();
      } else {
        this.doUpdate();
      }
    }

    async doCreate() {
      try {
        this.clicked = true;

        const response = await EstateService.create({
          vendorId: this.clickedItem.vendorId,
          name: this.clickedItem.name,
          shortCode: this.clickedItem.shortCode,
          address: this.clickedItem.address,
          contactNumber: this.clickedItem.contactNumber,
          defaultCodeLimit: this.clickedItem.defaultCodeLimit,
        });

        if (response && response.data) {
          if (response.data.result && response.data.result === 'false') {
            AppService.errorHandler(response.data.message);
          } else {
            AppService.successHandler(response.data.message);
            await this.listAll();
            this.showItemDialog = false;
          }
        } else {
          // response is undefined or has no data field - SHOULD NEVER HAPPEN!
          throw new Error('response: ' + JSON.stringify(response));
        }

      } catch (error) {
        if (error.response) {
          AppService.errorHandler(error.response.statusText);
        } else {
          await AuthService.logSupportDebug('Estates.vue - doCreate - 397 - ' + error);
          AppService.errorHandler(this.$store.getters['app/messages'].couldNotConnect);
        }

      } finally {
        this.clicked = false;
      }
    }

    async doUpdate() {
      try {
        this.clicked = true;
        this.confirmActionDelay = true;

        const response = await EstateService.update({
          id: this.clickedItem.id,
          vendorId: this.clickedItem.vendorId,
          name: this.clickedItem.name,
          shortCode: this.clickedItem.shortCode,
          address: this.clickedItem.address,
          contactNumber: this.clickedItem.contactNumber,
          defaultCodeLimit: this.clickedItem.defaultCodeLimit,
          suspended: this.clickedItem.suspended,
        });

        if (response && response.data) {
          if (response.data.result && response.data.result === 'false') {
            AppService.errorHandler(response.data.message);
          } else {
            AppService.successHandler(response.data.message);
            await this.listAll();
            this.showItemDialog = false;
            this.showConfirmActionDialog = false;
          }
        } else {
          // response is undefined or has no data field - SHOULD NEVER HAPPEN!
          throw new Error('response: ' + JSON.stringify(response));
        }

      } catch (error) {

        if (error.response) {
          AppService.errorHandler(error.response.statusText);
        } else {
          await AuthService.logSupportDebug('Drivers.vue - doUpdate - 663 - ' + error);
          AppService.errorHandler(this.$store.getters['app/messages'].couldNotConnect);
        }

      } finally {
        this.clicked = false;
        this.confirmActionDelay = false;
      }
    }
    // #endregion

    // #region Functions required for the Confirm Action Dialog for Delete
    launchActivateItemDialog(item: Estate) {
      this.clickedItem = item;

      this.action = 'ACTIVATE';
      this.dialogContent = 'You are about to <span style="font-weight: 700;color: #71BC78">re-activate</span> the Estate named <span style="font-weight: 700">' 
        + item.name + '</span>.<br/><br/> Are you sure?';

      this.showConfirmActionDialog = true;
    }

    launchSuspendItemDialog(item: Estate) {
      this.clickedItem = item;

      this.action = 'SUSPEND';
      this.dialogContent = 'You are about to <span style="font-weight: 700;color: #C01212 ">suspend</span> the Estate named <span style="font-weight: 700">' 
        + item.name + '</span>.<br/><br/> Are you sure?';

      this.showConfirmActionDialog = true;
    }

    ConfirmActionDialog_cancel() {
      this.showConfirmActionDialog = false;
    }

    async ConfirmActionDialog_doConfirmed() {
      if (this.action === 'SUSPEND') { 
        this.clickedItem.suspended = true;
        await this.doUpdate();

      } else if (this.action === 'ACTIVATE') {
        this.clickedItem.suspended = false;
        await this.doUpdate();

      }
    }

  // #endregion
}
